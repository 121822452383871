import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Tankstation } from './tankstation';
import { Kenteken } from './kenteken';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const apiUrl = "https://api.tankster.nl/v1";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getStations(lat: number, lon: number, fuel: string, usage: number): Observable<Tankstation[]> {
    const url = `${apiUrl}/tankster`;
    return this.http.post<Tankstation[]>(url, {lat: lat, lon: lon, fuel: fuel, usage: usage}).pipe(
      tap(_ => console.log(`fetched stations`))
    );
  }

  getStationList(lat: number, lon: number, fuel: string): Observable<Tankstation[]> {
    const url = `${apiUrl}/stations`;
    return this.http.post<Tankstation[]>(url, {lat: lat, lon: lon, fuel: fuel}).pipe(
      tap(_ => console.log(`fetched stations`))
    );
  }
  
  getKenteken(kenteken: string): Observable<Kenteken> {
    const url = `${apiUrl}/kenteken/${kenteken}`;
    return this.http.get<Kenteken>(url).pipe(
      tap(_ => console.log(`fetched kenteken`))
    );
  }
}
